<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports"/>
      </v-col>
    </v-row>
    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50
          "
        >

          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04) ">
                <p class="pa-4 font-extrabold text-xl text-blue" >Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row >
            <v-col cols="2">
              <v-autocomplete
                  v-model="searchParams.venueServiceId"
                  outlined
                  background-color="#fff"
                  placeholder="Services"
                  item-value="id"
                  item-text="name"
                  return-object
                  :items="venueServices"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      outlined
                      background-color="#fff"

                      v-model="date1Formatted"
                      readonly
                      v-on="on"
                      class="q-text-field shadow-0"
                      dense
                      hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.from_date"
                    @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      class="q-text-field shadow-0"
                      dense
                      hide-details
                      v-model="date2Formatted"
                      outlined
                      background-color="#fff"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.to_date"
                    @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                >Go</v-btn
                >
              </v-flex>
            </v-col>

            <v-spacer></v-spacer>

          </v-row>

          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col>Occupancy Rate Report </v-col>
                  <v-spacer></v-spacer>
                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-if="checkExportPermission($modules.reports.service.slug)"

                            class="export-button mt-2"
                            elevation="0"
                            height="40"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <SvgIcon text="Export Report" >
                            <template v-slot:icon>
                              <ExportIcon/>
                            </template>
                          </SvgIcon>
                        </v-btn>

                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>

            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="
                      md-content md-table-content md-scrollbar md-theme-default
                    "
                  >
                    <table class="logTable">
                      <thead class="md-card-header">
                      
                      </thead>

                      <tbody>
                        <template v-if="occupancyReportAcademy.length">
                          <tr class="md-table-row head_bg">
                            <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                              colspan="4"
                            >
                              <div class="md-table-cell-container">
                                Academy Booking
                              </div>
                            </td>
                          </tr>
                           <tr class="md-table-row">
                            <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container text-center">
                                Academy Name
                              </div>
                            </td>
                            
                             <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container">
                               Target YTD
                              </div>
                            </td>
                             <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container">
                                Actual YTD
                              </div>
                            </td>
                            <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container">
                               Percent of Achievement
                              </div>
                            </td>
                          </tr>
                          <template v-for="(data, Index) in occupancyReportAcademy">
                           <tr class="md-table-row"
                           :key="`data_${Index}`"
                            >
                             <td
                              :key="`data_${Index}`"
                              class="md-table-cell "
                            >
                              <div class="md-table-cell-container text-center">
                                {{data.workshop_name+" | "+data.program_name}}
                              </div>
                            </td>
                             
                             <td
                              :key="`data_${Index}`"
                              class="md-table-cell "
                            >
                              <div class="md-table-cell-container text-center">
                                {{data.total_class}}
                              </div>
                            </td>
                             <td
                              :key="`data_${Index}`"
                              class="md-table-cell"
                            >
                              <div class="md-table-cell-container text-center">
                                {{data.sales_quantity}}
                              </div>
                            </td>
                            <td
                              :key="`data_${Index}`"
                              class="md-table-cell"
                            >
                              <div class="md-table-cell-container text-center">
                               {{ data.sales_quantity === 0 ? 0 : Math.round((data.sales_quantity / data.total_class) * 100 * 100) / 100 }}%
                              </div>
                            </td>
                           </tr>
                          </template>
                        </template>

                        <template v-if="occupancyReportEvent.length">
                          <tr class="md-table-row head_bg">
                            <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                              colspan="4"
                            >
                              <div class="md-table-cell-container">
                                Event Booking
                              </div>
                            </td>
                          </tr>
                           <tr class="md-table-row">
                            <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container text-center">
                                Event Ticket Name
                              </div>
                            </td>
                            
                             <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container">
                                Target YTD
                              </div>
                            </td>
                             <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container">
                               Actual YTD
                              </div>
                            </td>
                            <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container">
                               Percent of Achievement
                              </div>
                            </td>
                          </tr>
                          <template v-for="(data, Index) in occupancyReportEvent">
                           <tr class="md-table-row"
                           :key="`data_${Index}`"
                            >
                             <td
                              :key="`data_${Index}`"
                              class="md-table-cell "
                            >
                              <div class="md-table-cell-container text-center">
                                {{data.event_name+" | "+data.ticket_name}}
                              </div>
                            </td>
                             
                             <td
                              :key="`data_${Index}`"
                              class="md-table-cell "
                            >
                              <div class="md-table-cell-container text-center">
                                {{data.total_quantity}}
                              </div>
                            </td>
                             <td
                              :key="`data_${Index}`"
                              class="md-table-cell"
                            >
                              <div class="md-table-cell-container text-center">
                                {{data.sales_quantity}}
                              </div>
                            </td>
                            <td
                              :key="`data_${Index}`"
                              class="md-table-cell"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ data.sales_quantity === 0 ? 0 : Math.round((data.sales_quantity / data.total_quantity) * 100 * 100) / 100 }}%
                              </div>
                            </td>
                           </tr>
                          </template>
                        </template>
                        <template v-if="occupancyReportFacility.length">
                          <tr class="md-table-row head_bg">
                            <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                              colspan="4"
                            >
                              <div class="md-table-cell-container">
                                Facility Booking
                              </div>
                            </td>
                          </tr>
                           <tr class="md-table-row">
                            <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container text-center">
                                Facility Name
                              </div>
                            </td>
                             <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container">
                                Target YTD
                              </div>
                            </td>
                             <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container">
                                Actual YTD
                              </div>
                            </td>
                            <td
                              class="
                                md-table-cell-container
                                text-center
                                font-weight-black
                              "
                            >
                              <div class="md-table-cell-container">
                               Percent of Achievement
                              </div>
                            </td>
                          </tr>
                          <template v-for="(data, Index) in occupancyReportFacility">
                           <tr class="md-table-row"
                           :key="`data_${Index}`"
                            >
                             <td
                              :key="`data_${Index}`"
                              class="md-table-cell "
                            >
                              <div class="md-table-cell-container text-center">
                                {{data.facility_name}}
                              </div>
                            </td>
                             <td
                              :key="`data_${Index}`"
                              class="md-table-cell "
                            >
                              <div class="md-table-cell-container text-center">
                                {{data.total_quantity}}
                              </div>
                            </td>
                             <td
                              :key="`data_${Index}`"
                              class="md-table-cell"
                            >
                              <div class="md-table-cell-container text-center">
                                {{data.booked_quantity}}
                              </div>
                            </td>
                            <td
                              :key="`data_${Index}`"
                              class="md-table-cell"
                            >
                              <div class="md-table-cell-container text-center">
                                 {{ data.booked_quantity === 0 ? 0 : Math.round((data.booked_quantity / data.total_quantity) * 100 * 100) / 100 }}%
                               
                              </div>
                            </td>
                           </tr>
                          </template>
                        </template>
                    </tbody>
                     
                  </table>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";

export default {
  components: {SvgIcon, BackButton,ExportIcon},
  data() {
    return {
      occupancyReportFacility: [],
      occupancyReportEvent: [],
      occupancyReportAcademy: [],
      services: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        venueServiceId: null,
        from_date: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      }
    };
  },
  watch: {
   
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
   
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
       this.$store.dispatch("loadVenueServices").then((data) => {
        if (data.length) {
          this.searchParams.venueServiceId = data[0];
        }
      });
     
    }
  },
  methods: {
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.searchParams[field].length == this[type].length) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type].map((item) => item.id);
        }
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (this.searchParams[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.searchParams[field].length > 0 &&
        this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },
    getReport() {
       this.occupancyReportFacility =[];
            this.occupancyReportEvent = [];
            this.occupancyReportAcademy = [];
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/occupancy-rate-report${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.occupancyReportFacility = data['facility'];
            this.occupancyReportEvent = data['event'];
            this.occupancyReportAcademy = data['academy'];
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "occupancy-rate-report-pdf/download";
      } else if (type == "excel") {
        link = "occupancy-rate-report-excel/download";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.$http
        .get(`venues/reports/` + link + `${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }


    
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Occupancy-Rate-Report-" + this.fileName;
      else this.fileName = "Occupancy-Rate-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function(opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
  },
};
</script>

<style scoped>
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}
.section_head_bg {
  background: #e1fcfc;
}
</style>
